import React, { useEffect } from "react";
import Meta from "components/Meta";
import HomeHero from "components/HomeHero";
import HomeBigImage from "components/HomeBigImage";
import HomeSmallImages from "components/HomeSmallImages";
import HomeCta from "components/HomeCta";
import { useRouter } from "next/router";

function IndexPage() {
  const router = useRouter();

  useEffect(() => {
    if (process.env.SELF_HOSTED) {
      router.push("/dashboard");
    }
  });

  return (
    <div>
      <Meta
        title="Make decisions anywhere"
        description="Add remarkably powerful decision-making capabilities to any automated business process."
      />
      <div className="hidden -z-10 lg:block h-[100vh] w-screen translate-y-44 bg-cover bg-center scale-150 opacity-[0.065] absolute bg-no-repeat bg-[url('/static/images/cover-vector.svg')]" />
      <div className="hidden -z-10 lg:block h-[100vh] w-screen translate-y-44 bg-cover bg-center scale-150 absolute bg-no-repeat opacity-90 bg-gradient-to-t via-transparent from-white " />
      <section>
        {/*
        <div>
          <img
            className="hidden lg:flex max-w-[20%] absolute object-fixed w-screen z-0 translate-x-3/4 scale-150 translate-y-12 opacity-10 transform lg:h-full"
            src="/static/images/cover-vector.svg"
            alt=""
          />
        </div>
        */}
        <HomeHero />
      </section>
      <section>
        <HomeBigImage />
      </section>
      <section>
        <HomeSmallImages />
      </section>
      <section>
        <HomeCta />
      </section>
    </div>
  );
}

export default IndexPage;
